import React from 'react'
import SelectedClients from '../selected-clients'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import GreaterGoodsCo from '../../icons/greater-goods-co.svg'
import Spotify from '../../icons/spotify.svg'
import Instagram from '../../icons/instagram.svg'
import * as styles from './footer.module.css'

const Footer = () => {
  let d = new Date();
  let currentYear = d.getFullYear();

  const data = useStaticQuery(
    graphql`
      query footerQuery {
        allContentfulGeneral {
          edges {
            node {
              spotifyUrl              
              licensingForm
            }
          }
        }
      }
    `
  )

  const general = data.allContentfulGeneral.edges      

  const spotifyUrl = general.map(c => (
    c.node.spotifyUrl
  ))

  const form = general.map(c => (
    c.node.licensingForm
  ))
  
  return (
    <>
      <SelectedClients />
      <footer className={styles.footer}>
        <Link to="/" className={styles.siteLogo}>
          <GreaterGoodsCo />
        </Link>
        <div className={styles.mantras}>
          <h2 className={styles.h2}>sync licensing</h2>
          <h2 className={styles.h2}>brand partnerships</h2>
          <h2 className={styles.h2}>publishing</h2>
          <p>
            There’s a duality to the meaning of Greater Goods and it’s more of a mantra than a name. For us it means that we’re focused on providing opportunities for independent artists to keep doing what they’re doing. It’s also a sign that though there’s lots of good music out there, we’re zeroing in on what’s great.
          </p>
        </div>
        <div className={styles.award}>
          <img src="/images/CANADIAN_SYNC_AWARDS_LOGO.png" alt="" />
          <p>Winner: Best Sync Team</p>
        </div>
        <div className={styles.licensingForm}>
          <h2 className={styles.h2}>licensing form</h2>
          <p>Obtain a license for use of a song within an audio-visual production</p>
          <a
            href={form}
            target="_blank"
            rel="noopener noreferrer">
            licensing form
          </a>
        </div>
        <div className={styles.contactUs}>
          <h2 className={styles.h2}>contact us</h2>
          <address>
            <p className={styles.email}>
              <a href="mailto:info@thegreatergoodsco.com" target="_blank" rel="noopener noreferrer">
                info@thegreatergoodsco.com
              </a>
            </p>
            <ul className={styles.social}>
              <li>
                <a
                  href={spotifyUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Spotify />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/thegreatergoods_co/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Instagram />
                </a>
              </li>
            </ul>
          </address>
          <p className={styles.copyright}><span>&copy;</span>{currentYear} Greater Goods Co.</p>
        </div>
      </footer>
    </>
  )
}

export default Footer
