import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import GreaterGoodsCo from '../../icons/greater-goods-co.svg'
import * as styles from './header.module.css'

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query GeneralQuery {
        allContentfulGeneral {
          edges {
            node {
              spotifyUrl
              spotifyTitle
              licensingForm
            }
          }
        }
      }
    `
  )

  const general = data.allContentfulGeneral.edges    
  const spotifyTitle = general.map(c => (
    c.node.spotifyTitle
  ))

  const spotifyUrl = general.map(c => (
    c.node.spotifyUrl
  ))

  const form = general.map(c => (
    c.node.licensingForm
  ))

  return (
    <header className={styles.siteHeader}>
      <nav className={styles.leftNav}>
        <ul>
          <li className={styles.navigationItem}>
            <Link to="/artists">ARTISTS</Link>
          </li>
          <li className={styles.navigationItem}>
            <Link to="/placements">PLACEMENTS</Link>
          </li>
          <li className={styles.navigationItem}>
            <a
              href={form}
              target="_blank"
              rel="noopener noreferrer">
              LICENSING FORM
            </a></li>
        </ul>
      </nav>

      <Link to="/" className={styles.siteLogo}>
        <GreaterGoodsCo />
      </Link>

      <nav className={styles.rightNav}>
        <ul>
          <li className={styles.navigationItem}>
            <a
              href={spotifyUrl}
              target="_blank"
              rel="noopener noreferrer">
              {spotifyTitle}
            </a>
          </li>
          <li className={styles.navigationItem}>
            <a
              href="https://www.instagram.com/thegreatergoods_co/"
              target="_blank"
              rel="noopener noreferrer">
              INSTAGRAM {'>'}
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header