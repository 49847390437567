import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ClientGrid from '../client-grid'
import Img from 'gatsby-image'
import * as styles from './selected-clients.module.css'

const SelectedClients = () => {
  const data = useStaticQuery(
    graphql`
      query SelectedClientsQuery {
        allContentfulClient(filter: {tag: {eq: "Selected"}}, sort: {fields: title, order: ASC}) {
          nodes {
            id
            link
            title
            image {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  )

  const clients = data.allContentfulClient.nodes

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <h2>selected clients</h2>
      </div>
      <ClientGrid>
        {clients.map(c => (
          <li key={c.id}>
            <a href={c.link} target="_blank" rel="noopener noreferrer">
              <Img alt={c.title} fluid={c.image.fluid} />
            </a>
          </li>
        ))}
      </ClientGrid>
    </section>
  )
}

export default SelectedClients
