import './base.css'
import './global.css'
import React from 'react'
import Container from './container'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  return (
    <Container>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </Container>
  )
}

export default Layout
