import React from 'react'
import * as styles from './client-grid.module.css'

const ClientGrid = ({ children }) => {
  return (
    <ul className={styles.grid}>
      {children}
    </ul>
  )
}

export default ClientGrid
